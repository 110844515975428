
import { SUPPORTED_LOCALES } from "@/plugins/i18n/config";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
  },
  props: {
    hideIcon: {
      type: Boolean,
    },
    variantStyle: {
      type: Boolean,
    },
    codesOnly: {
      type: Boolean,
    },
  },
  setup: function () {
    const { t, locale } = useI18n();

    return {
      locale,
      locales: SUPPORTED_LOCALES,
      t
    };
  },
  methods: {
    changeLocale: async function (): Promise<void> {
      this.$router.replace({ params: { locale: this.locale } });
    },
  },
});
