import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-611c8a58"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "vp-selected-option"
}
const _hoisted_2 = { class: "block md:hidden font-bold" }
const _hoisted_3 = { class: "hidden md:block font-bold" }
const _hoisted_4 = { class: "sm:inline md:hidden" }
const _hoisted_5 = { class: "hidden md:inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    class: "vp-dropdown",
    modelValue: _ctx.locale,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locale) = $event)),
    options: _ctx.locales,
    onChange: _ctx.changeLocale
  }, {
    value: _withCtx((slotProps) => [
      (slotProps.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(slotProps.value.toUpperCase()), 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t(`general.languages.${slotProps.value}`)), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    option: _withCtx((slotProps) => [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(slotProps.option.toUpperCase()), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t(`general.languages.${slotProps.option}`)), 1)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "options", "onChange"]))
}