
import { defineComponent, ref } from "vue";
import ONavBar from "@/components/organisms/o-nav-bar.vue";
import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    ONavBar,
  },
  setup() {
    const { meta } = useRoute();
    const hideNavigation = ref(Boolean(meta.hideNavigation));
    return {
      hideNavigation,
    };
  },
});
