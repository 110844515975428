
import { defineComponent, ref } from "vue";
import golazoLogo from "@/assets/golazo-logo.png";
import MLanguageSwitcher from "@/components/molecules/m-language-switcher.vue";
import { useI18n } from "vue-i18n";
import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import { useMsal } from "@/composition-api/useMsal";
import manageAccountRequest, {
  loginRequestWithLocale
} from "@/plugins/msal/config";
import { getFromVueEnv } from "@/helpers/misc";

export default defineComponent({
  name: "ONavBar",
  components: {
    MLanguageSwitcher,
  },
  computed:{
    items(): { label: string, command: ()=> void}[] {
      return[
        {
          label: this.t("general.account.title"),
          command: () => {
            this.manageAccount();
          },
        },
        {
          label: this.$t("logout.title"),
          command: () => this.logout(),
        },
      ]
    }
  },
  methods: {
    toggle(event: any): void {
      (this.$refs as any).menu.toggle(event);
    },
    login(): void {
      this.instance.loginRedirect(loginRequestWithLocale(this.locale));
    },
    logout(): void {
      this.instance.logoutRedirect();
    },
    manageAccount(): void {
      this.instance.loginRedirect(manageAccountRequest(this.locale));
    },
    shouldManageProfile() {
      return getFromVueEnv("MANAGE_PROFILE") === "true";
    }
  },
  setup() {
    const { isAuthenticated, user } = useIsAuthenticated();
    const { instance } = useMsal();
    const { t, locale } = useI18n();
    
    return {
      user,
      logo: golazoLogo,
      isAuthenticated,
      t,
      locale,
      instance,
    };
  },
});
