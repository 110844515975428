import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39bf0ab0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vp-nav flex w-full align-items-center font-bold justify-content-between" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "vp-account flex align-items-center justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_m_language_switcher = _resolveComponent("m-language-switcher")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_ctx.shouldManageProfile())
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: `/${_ctx.$i18n.locale}/`
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              class: "vp-logo",
              src: _ctx.logo
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "vp-logo",
          src: _ctx.logo
        }, null, 8, _hoisted_3)),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_m_language_switcher),
      (_ctx.shouldManageProfile())
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.isAuthenticated)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  class: "vp-nav-button p-button-plain p-button-text font-bold m-2",
                  label: _ctx.$t('login.title'),
                  onClick: _ctx.login
                }, null, 8, ["label", "onClick"]))
              : (_openBlock(), _createBlock(_component_Button, {
                  key: 1,
                  class: "vp-nav-button p-button-text p-button-plain font-bold",
                  type: "button",
                  iconPos: "right",
                  icon: "pi pi-chevron-down",
                  label: _ctx.user?.displayName,
                  onClick: _ctx.toggle
                }, null, 8, ["label", "onClick"])),
            _createVNode(_component_Menu, {
              ref: "menu",
              popup: true,
              model: _ctx.items
            }, null, 8, ["model"])
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}